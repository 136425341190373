import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_RefinanceCalculator_Config({ widget, onChange }) {
  return (
    <React.Fragment>
      <Grid container spacing={2} direction='row'>
        <Grid item xs={6}>
          <Box className="Input">
            <TextField
              label="Apply Now Link Url"
              multiline
              maxRows={3}
              fullWidth
              value={widget.applyNowLinkUrl}
              onChange={(event) => onChange("applyNowLinkUrl", event.target.value)}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="Input">
            <TextField
              label="Memo"
              multiline
              maxRows={3}
              fullWidth
              value={widget.memo}
              onChange={(event) => onChange("memo", event.target.value)}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
