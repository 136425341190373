import * as React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { render } from '@testing-library/react';
import Api from './Api';
import { LensTwoTone } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { TextareaAutosize } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';

export default class ConfigurationModal extends React.Component {

  state = {
    tabValue: 0,
    config: null,
    cuName: "",
    isNew: false,
  }

  constructor(props) {
    
    super(props);

    this.onClose = props.onClose;
    this.state.config = props.config;
    
    
    if (!this.state.config.siteId) {
      this.state.isNew = true;
      this.state.cuName = props.config.name;
      this.state.title = "Add New Credit Union";
    } else {
      this.state.cuName = '';
      this.state.title = "Edit Configuration";
    }
  }


  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  }

  close = (save) => {
    this.onClose(save);
  };

  onChange = (field, value) => {
    var config = JSON.parse(JSON.stringify(this.state.config));
    config[field] = value;
    this.setState({config});
  }

  render() {
    var config = this.state.config;
    var props = this.props;
    
    return (
      <React.Fragment>
          <Dialog maxWidth="md" fullWidth={true} onClose={this.handleClose} open={true}>
            <DialogTitle>
              <Typography variant="h5"><strong>{this.state.cuName}</strong></Typography>
              <Typography variant="subtitle1">{this.state.title}</Typography> 
            </DialogTitle>
            <DialogContent>
              <Box style={{border:"solid 1px #d3d3d3", borderRadius: "5px"}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="basic tabs example">
                    <Tab label="Configuration" {...tabProps(0)} />
                    <Tab label="Other" {...tabProps(1)} />
                    <Tab label="Stylesheet" {...tabProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={0}>
                  <Paper className="paper" variant="elevation">
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Mortgage Cadence Site ID"
                          disabled={!this.state.isNew}
                          style={{width: "100%"}}
                          value={config.siteId}
                          onChange={(event) => this.onChange("siteId", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Name"
                          style={{width: "100%"}}
                          value={config.name}
                          onChange={(event) => this.onChange("name", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Velocify - Institution ID"
                          style={{width: "100%"}}
                          value={config.institutionId}
                          onChange={(event) => this.onChange("institutionId", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Optimal Blue - Business Channel ID (v5+)"
                          style={{width: "100%"}}
                          value={config.businessChannelId}
                          onChange={(event) => this.onChange("businessChannelId", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel className="selectLabelCorrection">Api Version</InputLabel>
                        <Select
                          value={config.apiVersion}
                          label="Api Version"
                          onChange={(event) => this.onChange("apiVersion", event.target.value)}
                          variant="outlined"
                        >
                          <MenuItem value={99}>UAT</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                  </Paper>
                </TabPanel>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={1}>
                  <Paper className="paper" variant="elevation">
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel className="selectLabelCorrection">Lock Period</InputLabel>
                        <Select
                          value={config.lockPeriod}
                          label="Lock Period"
                          onChange={(event) => this.onChange("lockPeriod", event.target.value)}
                          variant="outlined"
                        >
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={45}>45</MenuItem>
                          <MenuItem value={60}>60</MenuItem>
                          <MenuItem value={90}>90</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Logo URL"
                          style={{width: "100%"}}
                          value={config.logo}
                          onChange={(event) => this.onChange("logo", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextField
                          label="Website URL"
                          style={{width: "100%"}}
                          value={config.siteUrl}
                          onChange={(event) => this.onChange("siteUrl", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <InputLabel className="selectLabelCorrection">Disclaimer</InputLabel>
                      <TextareaAutosize
                          className="text-area"
                          aria-label="Disclaimer"
                          style={{width: "100%", maxWidth: "100%"}}
                          minRows={4}
                          value={config.disclaimer}
                          onChange={(event) => this.onChange("disclaimer", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={2}>
                  <Paper className="paper" variant="elevation">
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextareaAutosize
                          className="text-area"
                          aria-label="Stylesheet (CSS)"
                          style={{width: "100%", maxWidth: "100%"}}
                          minRows={16}
                          value={config.stylesheet}
                          onChange={(event) => this.onChange("stylesheet", event.target.value)}
                          variant="outlined"
                        />
                    </Box>
                  </Paper>
                </TabPanel>
              </Box>
            </DialogContent>
            <DialogActions className="DialogActions">
              <Button onClick={() => this.close(null)}>Cancel</Button>
              <Button onClick={() => this.close(config)} variant="contained" color="primary">Save</Button>
            </DialogActions>
          </Dialog>
      </React.Fragment>
    );
  }
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
