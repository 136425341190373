import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit'

export default function CreditUnionConfigTable(props) {
  var config = props.config;
  
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
            <TableRow>
                <TableCell colSpan={2} style={{background:"#d6e2fb"}}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" component="div" align="center">
                          Configuration
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      { true &&
                          <Typography variant="h6" component="div" align="right">
                            <Link size="small" onClick={() => props.editConfig()}>
                                <EditIcon style={{float:"right"}} />
                            </Link>
                          </Typography>
                      }
                    </Grid>
                  </Grid>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Site ID (v4 use MC, v5 use unique)</TableCell>
                <TableCell align="left">{config.siteId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{config.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Velocify - Institution ID</TableCell>
                <TableCell align="left">{config.institutionId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Optimal Blue - Business Channel ID (v5+)</TableCell>
                <TableCell align="left">{config.businessChannelId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">API Version</TableCell>
                { config.apiVersion != 99 && <TableCell align="left">{config.apiVersion}</TableCell> }
                { config.apiVersion == 99 && <TableCell align="left">UAT</TableCell> }
            </TableRow>
            <TableRow>
                <TableCell align="left">Lock Period</TableCell>
                <TableCell align="left">{config.lockPeriod}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Logo Url</TableCell>
                <TableCell align="left">
                { config.logo && <Link href={config.logo} target="_blank">
                    {config.logo}
                  </Link>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Website Url</TableCell>
                <TableCell align="left">
                  {config.siteUrl && <Link href={config.siteUrl}>
                    {config.siteUrl}
                  </Link> }
                </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
