import * as React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import WidgetEditor_MicroWidget_Config from './WidgetEditor_MicroWidget_Config';
import WidgetEditor_TodaysRatesCards_Config from './WidgetEditor_TodaysRatesCards_Config';
import WidgetEditor_TodaysRatesCards_Products from './WidgetEditor_TodaysRatesCards_Products';
import WidgetEditor_TodaysRatesTable_Config from './WidgetEditor_TodaysRatesTable_Config';
import WidgetEditor_TodaysRatesTable_Products from './WidgetEditor_TodaysRatesTable_Products';
import WidgetEditor_PaymentCalculator_Config from './WidgetEditor_PaymentCalculator_Config';
import WidgetEditor_PaymentCalculator_Products from './WidgetEditor_PaymentCalculator_Products';
import WidgetEditor_HowMuchDoINeedCalculator_Config from './WidgetEditor_HowMuchDoINeedCalculator_Config';
import WidgetEditor_HowMuchDoINeedCalculator_Products from './WidgetEditor_HowMuchDoINeedCalculator_Products';
import WidgetEditor_HowMuchCanIAffordCalculator_Config from './WidgetEditor_HowMuchCanIAffordCalculator_Config';
import WidgetEditor_HowMuchCanIAffordCalculator_Products from './WidgetEditor_HowMuchCanIAffordCalculator_Products';
import WidgetEditor_RentVsBuyCalculator_Config from './WidgetEditor_RentVsBuyCalculator_Config';
import WidgetEditor_RefinanceCalculator_Config from './WidgetEditor_RefinanceCalculator_Config';
import { render } from '@testing-library/react';
import Api from './Api';
import { LensTwoTone, ThreeSixty } from '@material-ui/icons';
import WidgetEditor_RefinanceCalculator_Products from './WidgetEditor_RefinanceCalculator_Products';


export default class WidgetEditorModal extends React.Component {

  state = {
    tabValue: 0,
    products: []
  }

  constructor(props) {
    super(props);
    this.onClose = props.onClose;
    this.state.widget = props.widget;
    this.state.config = props.config;
  }

  componentDidMount() {
    if (this.state.widget.id == "NEW") {
      var widget = JSON.parse(JSON.stringify(this.state.widget));
      widget.id = "w" + this.generateGUID().substring(0, 8);

      this.setState({ widget });
      this.setState({ isNew: true });
      this.handleTabChange(null, 1);
    }

    console.log("Widget Config::", this.state.widget);

    var api = new Api();
    api.getProducts(this.state.config.siteId, this.state.config.apiVersion, results => {
      this.setState({ products: results });
    });

  }

  generateGUID() {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });

    if (newValue == 3) {

      //var content = "<html><body>Hello world</body></html>";

      var scripts = this.compileImplementationScripts();

      var content = "<html><head>";
      content += '<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>';
      content += scripts[0];
      content += "</head><body>";
      content += scripts[1];
      content += "</body></html>";

      setTimeout(function () {
        document.getElementById('preview').contentWindow.document.write(content);
      })
    }
  }

  handleChange = (field, value, type) => {
    this.setState(prevState => {
      let widget = Object.assign({}, prevState.widget);
      if (type == 'number') {
        widget[field] = value ? Number(value) : null;
      } else if (type == 'boolean') {
        widget[field] = !!value;
      } else {
        widget[field] = value;
      }

      return { widget };
    })
  }

  close = (save) => {
    this.onClose(save);
  };

  compileShortcodeScriptsForMemberAdvantageSiteOnly() {

    var shortCodeName = "";
    if (this.state.widget.type === 'TodaysRatesCards') {
      shortCodeName = 'MAM_Widgetcards';
    } else if (this.state.widget.type === 'TodaysRatesTable') {
      shortCodeName = 'MAM_Widgettable';
    } else if (this.state.widget.type === 'PaymentCalculator') {
      shortCodeName = 'MAM_PaymentCalculator';
    } else if (this.state.widget.type === 'MicroWidget') {
      shortCodeName = 'MAM_Widgetmicro';
    } else if (this.state.widget.type === 'RentVsBuyCalculator') {
      shortCodeName = 'MAM_RentVsBuyCalculator';
    } else if (this.state.widget.type === 'HowMuchCanIAffordCalculator') {
      shortCodeName = 'MAM_HowMuchCanIAffordCalculator';
    } else if (this.state.widget.type === 'HowMuchDoINeedCalculator') {
      shortCodeName = 'MAM_HowMuchDoINeedCalculator';
    }
    // will need to be updated when refi calculator is ready and loses the "WIP" designation
    else if (this.state.widget.type === 'RefiCalculatorWIP') {
      shortCodeName = 'MAM_RefiCalculatorWIP';
    }

    const result = `[${shortCodeName} widgetid='${this.state.widget.id}' institutionid='${this.state.config.siteId}']`;
    return result;
  }

  compileImplementationScripts() {
    var results = [];
    results.push("<script type='text/javascript' src='" + process.env.REACT_APP_MAM_GATEWAY_API_URL + "mamscripts/" + this.state.config.siteId + "'></script>");

    if (this.state.widget.type === 'TodaysRatesCards') {
      results.push("<mam-todays-rates-cards id='" + this.state.widget.id + "'></mam-todays-rates-cards>");
    } else if (this.state.widget.type === 'TodaysRatesTable') {
      results.push("<mam-todays-rates-table id='" + this.state.widget.id + "'></mam-todays-rates-table>");
    } else if (this.state.widget.type === 'PaymentCalculator') {
      var calcInstruction = "<!-- To Embed in Page -->\n";
      calcInstruction += "<mam-payment-calculator id='" + this.state.widget.id + "'></mam-payment-calculator>";
      calcInstruction += "\n\n<!-- To Show in Modal -->\n";
      calcInstruction += "<mam-payment-calculator-modal id='" + this.state.widget.id + "'>\n  <a href='javascript:'>View Calculator</a>\n</mam-payment-calculator-modal>";
      results.push(calcInstruction);
    } else if (this.state.widget.type === 'MicroWidget') {
      results.push("<mam-micro-widget id='" + this.state.widget.id + "'></mam-micro-widget>");
    } else if (this.state.widget.type === 'RentVsBuyCalculator') {
      results.push("<mam-rent-vs-buy-calculator id='" + this.state.widget.id + "'></mam-rent-vs-buy-calculator>");
    } else if (this.state.widget.type === 'HowMuchCanIAffordCalculator') {
      results.push("<mam-how-much-can-i-afford-calculator id='" + this.state.widget.id + "'></mam-how-much-can-i-afford-calculator>");
    } else if (this.state.widget.type === 'HowMuchDoINeedCalculator') {
      results.push("<mam-how-much-do-i-need-calculator id='" + this.state.widget.id + "'></mam-how-much-do-i-need-calculator>");
    }
    // will need to be updated when refi calculator is ready and loses the "WIP" designation
    else if (this.state.widget.type === 'RefiCalculatorWIP') {
      results.push("<mam-refi-calculator-wip id='" + this.state.widget.id + "'></mam-refi-calculator-wip>");
    } else {
      results.push("INSTRUCTIONS FOR THIS WIDGET TYPE NOT YET PROVIDED")
    }
    return results;
  }

  render() {
    var widget = this.state.widget;
    var implementationScripts = this.compileImplementationScripts();
    var mamOnlyShortCode = this.compileShortcodeScriptsForMemberAdvantageSiteOnly();
    return (
      <React.Fragment>
        {widget != null &&
          <Dialog maxWidth="md" fullWidth={true} onClose={this.handleClose} open={true}>
            <DialogTitle>
              {this.state.isNew && <Typography variant="h5">Add {widget.type} Widget</Typography>}
              {!this.state.isNew && <Typography variant="h5">Edit {widget.type} Widget</Typography>}
            </DialogTitle>
            <DialogContent>
              <Box style={{ border: "solid 1px #d3d3d3", borderRadius: "5px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="basic tabs example">
                    <Tab label="Snippet" {...tabProps(0)} />
                    <Tab label="Configuration" {...tabProps(1)} />
                    <Tab label="Products" {...tabProps(2)} />
                    <Tab label="Preview" {...tabProps(3)} />

                  </Tabs>
                </Box>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={0}>
                  <Paper className="paper" variant="elevation">
                    <Typography variant="p">Append the following scripts to the closing body tag:</Typography>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextareaAutosize
                        className="text-area"
                        disabled
                        style={{ width: "90%", cursor: "text", maxWidth: "100%", padding: "10px" }}
                        minRows={4}
                        value={implementationScripts[0]}
                        variant="outlined"
                      />
                    </Box>
                    <Typography variant="p">Place your widget on your page with the following code:</Typography>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextareaAutosize
                        className="text-area"
                        disabled
                        style={{ width: "90%", cursor: "text", maxWidth: "100%", padding: "10px" }}
                        minRows={4}
                        value={implementationScripts[1]}
                        variant="outlined"
                      />
                    </Box>
                    <Typography variant="p">Shortcode to add this widget on the Member Advantage Mortgage Wordpress site only</Typography>
                    <Box className="Input" sx={{ minWidth: 120 }}>
                      <TextareaAutosize
                        className="text-area"
                        disabled
                        style={{ width: "90%", cursor: "text", maxWidth: "100%", padding: "10px" }}
                        minRows={4}
                        value={mamOnlyShortCode}
                        variant="outlined"
                      />
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={1}>
                  <Paper className="paper" variant="elevation">
                    {widget.type == "TodaysRatesCards" &&
                      <WidgetEditor_TodaysRatesCards_Config
                        widget={widget}
                        config={this.config}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "TodaysRatesTable" &&
                      <WidgetEditor_TodaysRatesTable_Config
                        widget={widget}
                        config={this.config}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "PaymentCalculator" &&
                      <WidgetEditor_PaymentCalculator_Config
                        widget={widget}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "MicroWidget" &&
                      <WidgetEditor_MicroWidget_Config
                        widget={widget}
                        config={this.config}
                        products={this.state.products}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "RentVsBuyCalculator" &&
                      <WidgetEditor_RentVsBuyCalculator_Config
                        widget={widget}
                        config={this.config}
                        products={this.state.products}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "RefiCalculatorWIP" &&
                      <WidgetEditor_RefinanceCalculator_Config
                        widget={widget}
                        config={this.config}
                        // products={this.state.products}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "HowMuchCanIAffordCalculator" &&
                      <WidgetEditor_HowMuchCanIAffordCalculator_Config
                        widget={widget}
                        config={this.config}
                        products={this.state.products}
                        onChange={this.handleChange}
                      />
                    }
                    {widget.type == "HowMuchDoINeedCalculator" &&
                      <WidgetEditor_HowMuchDoINeedCalculator_Config
                        widget={widget}
                        config={this.config}
                        products={this.state.products}
                        onChange={this.handleChange}
                      />
                    }

                  </Paper>
                </TabPanel>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={2}>
                  <Paper className="paper" variant="elevation">
                    {widget.type == "TodaysRatesCards" &&
                      <WidgetEditor_TodaysRatesCards_Products
                        products={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                    }
                    {widget.type == "TodaysRatesTable" &&
                      <WidgetEditor_TodaysRatesTable_Products
                        products={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                    }
                    {widget.type == "PaymentCalculator" &&
                      <WidgetEditor_PaymentCalculator_Products
                        products={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                    }
                    {widget.type == "MicroWidget" &&
                      <h4>Select product from the Configuration tab</h4>
                    }
                    {widget.type == "RentVsBuyCalculator" &&
                      <h4>Select product from the Configuration tab</h4>
                    }
                    {widget.type == "RefiCalculatorWIP" &&
                      <WidgetEditor_RefinanceCalculator_Products
                        widgetProducts={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                      }
                    {widget.type == "HowMuchDoINeedCalculator" &&
                      <WidgetEditor_HowMuchDoINeedCalculator_Products
                        products={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                    }
                    {widget.type == "HowMuchCanIAffordCalculator" &&
                      <WidgetEditor_HowMuchCanIAffordCalculator_Products
                        products={widget.products}
                        config={this.state.config}
                        changeHandler={this.handleChange}
                      />
                    }

                  </Paper>
                </TabPanel>
                <TabPanel className="tabPanel" value={this.state.tabValue} index={3}>
                  <Paper className="paper" variant="elevation">
                    <iframe id="preview" style={{ width: '100%', height: '500px' }}></iframe>
                    <h5>*** NOTE *** This preview reflects currently saved configuration</h5>
                  </Paper>
                </TabPanel>
              </Box>
            </DialogContent>
            <DialogActions className="DialogActions">
              <Button onClick={() => this.close(null)}>Cancel</Button>
              <Button onClick={() => this.close(widget)} variant="contained" color="primary">Save</Button>
              <div style={{ position: 'absolute', left: "20px" }}>Widget ID: {widget.id}</div>
            </DialogActions>
          </Dialog>
        }
      </React.Fragment>
    );
  }
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
