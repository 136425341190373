import * as React from 'react';
import axios from 'axios';

export default class Api {

    gatewayApiRoot = "unset";
    constructor() {
        this.gatewayApiRoot = process.env.REACT_APP_MAM_GATEWAY_API_URL;
        this.axiosOptions = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('mam-widget-admin-token')}`
            }
        }
    }

    getMamConfig = (apiVersion, callback) => {
        var url = `${process.env.REACT_APP_MAM_GATEWAY_API_URL}mam/config/${apiVersion}`;
        axios.get(url).then(result => {
            callback(result.data);
        });
    }

    saveMamConfig = (config, callback) => {
        var url = `${process.env.REACT_APP_MAM_GATEWAY_API_URL}mam/config`;
        axios.post(url, config, this.axiosOptions).then(result => {
            callback(result.data);
        })
        .catch(err => {
            callback(null, err);
        })
    }

    getCreditUnions = (callback) => {
        var url = `${this.gatewayApiRoot}sites`;

        axios.get(url).then(result => {
            var sites = result.data;
            callback(sites);
        });
    }

    getCreditUnion = (siteId, callback) => {
        var url = `${this.gatewayApiRoot}site/${siteId}/config`;

        axios.get(url).then(result => {
            var config = result.data;
            callback(config);
        });
    }

    saveConfig = (config, callback) => {
        var url = `${this.gatewayApiRoot}site/config`;

        axios.put(url, config, this.axiosOptions).then(result => {
            callback(result.data);
        })
        .catch(err => {
            callback(null, err);
        });
    }

    deleteConfig = (siteId, callback) => {
        var url = `${this.gatewayApiRoot}site/${siteId}/config/delete`;

        axios.post(url, undefined, this.axiosOptions).then(result => {
            callback(result.data);
        })
        .catch(err => {
            callback(null, err);
        });
    }

    getProducts = (siteId, apiVersion, callback) => {
        
        if (apiVersion == 99) { // UAT
            var url = `${process.env.REACT_APP_MAM_API_UAT_URL}rates/current/json/${siteId}`;
            axios.get(url).then(result => {
                var rates = result.data.rates;
                var results = rates.map(r => { return r.product});
                callback(results);
            }).catch(onError);
        } else if (apiVersion == 4) {
            var url = `${process.env.REACT_APP_MAM_API_V4_URL}rates/current/json/${siteId}`;
            axios.get(url).then(result => {
                var rates = result.data.rates;
                var results = rates.map(r => { return r.product});
                callback(results);
            }).catch(onError);
        } else if (apiVersion == 5) {
            var url = `${process.env.REACT_APP_MAM_API_V5_URL}rates/current/json/${siteId}`;
            axios.get(url).then(result => {
                var rates = result.data.rates;
                var results = rates.map(r => { return r.productCode}).sort((a,b) => { return a > b ? 1 : -1});
                callback(results);
            }).catch(onError);
        } else {
            
            callback([]);
        }

        function onError(err) {
            alert("Unable to retrieve products from API version " + apiVersion);
        }
    }

    authenticate = (token, callback) => {
        axios.post(`${process.env.REACT_APP_MAM_GATEWAY_API_URL}authenticate`, undefined, this.axiosOptions).then(result => {
            callback(result);
        })
        .catch(() => {
            callback(null);
        })
    }

    login = (passcode, callback) => {
        axios.post(`${process.env.REACT_APP_MAM_GATEWAY_API_URL}login`, { passcode } ).then(result => {
            callback(result);
        })
    }


}